<template>
	<div class="mlocalidadFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mlocalidad.idlocalidad')" :value="getIdlocalidadValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mlocalidad.codlocode')" :value="getCodlocodeValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mlocalidad.espuerto')" :value="getEspuertoValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mlocalidad-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mlocalidad'
		};
	},
	computed: {
		getIdlocalidadValue() {
			return this.model && this.model.idlocalidad ? this.model.idlocalidad : '-';
		},
		getCodlocodeValue() {
			return this.model && this.model.codlocode ? this.model.codlocode : '-';
		},
		getEspuertoValue() {
			return this.model && this.model.espuerto ? this.model.espuerto : '-';
		},
	}
}
</script>
